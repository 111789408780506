<template>
    <v-container>
        <v-row class="row">
            <v-col id="img-col">
                <v-row>
                    <v-col>
                        <v-img
                            id="img"
                            :src="img.src"
                            :height="img.height"
                            :width="img.width"
                        />
                    </v-col>
                </v-row>
                <v-row :class="{ 'remove-margin-bottom': leafletUrl }">
                    <v-col>
                        <v-btn
                            v-if="publicationInfo"
                            large
                            color="success"
                            :href="`/buy`"
                        >
                            Achetez ici
                            {{
                                publicationInfo.price
                                    | currency('€', 2, currencyOptions)
                            }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="leafletUrl" id="leaflet-row">
                    <v-col>
                        <v-btn
                            large
                            color="secondary"
                            link
                            :href="leafletUrl"
                            target="blank"
                            >Argumentaire</v-btn
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <h1>
                    <i>{{ title }}</i>
                </h1>
                <p>Par {{ authorsNames }}</p>
                <div id="description">
                    <p v-for="paragraph in description" :key="paragraph">
                        {{ paragraph }}
                    </p>
                    <!-- TODO: Remove the lines below -->
                    <!-- {{ description }} -->
                    <!-- {{ Object.keys(data) }} -->
                    <!-- {{ data.publication_info }} -->
                </div>
            </v-col>
        </v-row>
        <v-row v-if="publicationInfo">
            <v-divider></v-divider>
        </v-row>
        <v-row v-if="publicationInfo">
            <table>
                <tbody>
                    <tr>
                        <td>
                            Parution :
                            {{ publicationInfo.publicationDate }}
                        </td>
                        <td>Taille : {{ publicationInfo.size }}</td>
                    </tr>
                    <tr>
                        <td>
                            Collection : {{ publicationInfo.bookCollection }}
                        </td>
                        <td>ISBN : {{ publicationInfo.isbn }}</td>
                    </tr>
                    <tr>
                        <td>Série : {{ publicationInfo.series }}</td>
                        <td>
                            Prix :
                            {{
                                publicationInfo.price
                                    | currency('€', 2, currencyOptions)
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>Nombre de pages : {{ publicationInfo.nbPages }}</td>
                        <td>
                            Auteur{{ authors.length > 2 ? 's' : '' }} :
                            {{ authorsNames }}
                        </td>
                    </tr>
                    <tr>
                        <td>Format : {{ publicationInfo.bookFormat }}</td>
                        <td>
                            Illustration de couverture :
                            {{ publicationInfo.illustration }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';
export default {
    name: 'Opus',
    components: {},
    data: function () {
        return {
            // TODO: remove data after development
            data: {},
            title: '',
            authors: [],
            img: {
                src: '',
                width: 0,
                height: 0,
            },
            description: [],
            publicationInfo: null,
            currencyOptions: {
                symbolOnLeft: false,
                spaceBetweenAmountAndSymbol: true,
            },
            leafletUrl: null,
        };
    },
    computed: {
        authorsNames() {
            if (!this.authors.length) return '';
            if (this.authors.length < 2) return this.authors[0].nickname;
            const beginning = this.authors.slice(0, this.authors.length - 1);
            const lastAuthor = this.authors[this.authors.length - 1];
            const authorsNames = beginning
                .map((value) => value.nickname)
                .join(', ');
            return [authorsNames, lastAuthor.nickname].join(' et ');
        },
    },
    created: async function () {
        const res = await axios.get(
            process.env.VUE_APP_API_URL + `/opuses/${this.$route.params.id}`
        );

        this.data = res.data;
        // TODO: remove this line
        delete this.data.episodes;
        this.title = this.data.title;
        this.authors = this.data.authors;
        this.img = {
            src: this.data.illustration.formats.small.url,
            width: this.data.illustration.formats.small.width,
            height: this.data.illustration.formats.small.height,
        };
        this.description = this.data.description.split('\n');
        if (this.data.publication_info) {
            this.publicationInfo = this.data.publication_info;
            this.publicationInfo.publicationDate = this.formatDate(
                this.publicationInfo.publicationDate
            );
        }
        if (this.data.leaflet) {
            this.leafletUrl = this.data.leaflet.file.url;
        }
    },
    methods: {
        formatDate(date) {
            const splited = date.split('-');
            return [splited[2], splited[1], splited[0]].join('/');
        },
    },
};
</script>
<style scoped>
#description {
    text-align: justify;
    font-size: 1.5em;
    max-width: 80%;
    padding-top: 1.5em;
    margin: auto;
}

#description > p {
    margin-bottom: 0.5em;
}

#img-col {
    margin: auto;
}

#img {
    margin: auto;
}

table {
    margin: auto;
    font-size: 1.5em;
    border-spacing: 7em 0;
    text-align: left;
}

.row {
    margin-bottom: 2em;
}

#leaflet-row {
    margin-top: 0px;
}

.remove-margin-bottom {
    margin-bottom: 0px;
}
</style>
